<template>
  <section class="all-documents-view">
    <el-row :type="getIsMobile ? '' : 'flex'" justify="left" align="left">
      <el-col v-if="getIsMobile" :xl="8" :lg="8" :md="12" :sm="12" :xs="12">
          <h3 class="title fw-normal text-dark mb-0 ls-05" :style="getIsMobile ? 'margin-top:10px;margin-left:2px' : ''">Documents</h3>
        </el-col>
      <el-col v-if="!getIsMobile" :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
          <h3 class="title fw-normal text-dark mb-0 ls-05" style="width: 200px;">Request Documents</h3>
          <div class="document-types">
            <h5 @click="handleDocumentTypesClicked" class="title fw-normal text-dark mb-0 ls-05 document-types-left" style="width: 200px;">Document types</h5>
            <h5 class="title fw-normal text-dark mb-0 ls-05 document-types-right" style="width: 200px;">
              &nbsp;>&nbsp;{{document_type}}
            </h5>
          </div>
        </el-col>

        <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="12">
          <el-dropdown
            class="import-dropdown-btn"
            v-if="this.selected_document.length"
          >
            <el-button size="mini" class="el-dropdown-link px-2">
              Delete
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!-- <a
                v-if="
                  this.$route.query.type && this.$route.query.type == 'deleted'
                "
                @click="checkCondition"
              >
                <el-dropdown-item>
                  <i class="el-icon-delete-solid"></i>
                  <span>Permanent Delete</span>
                </el-dropdown-item>
              </a> -->
              <a @click="checkCondition">
                <el-dropdown-item>
                  <i class="el-icon-delete"></i>
                  <span>Delete</span>
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-col>
      <el-col
        :class="getIsMobile ? '' : 'mr-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        v-if="getIsMobile"
      >
        <el-input
          class="search px-0"
          placeholder="Search"
          v-model="search_string"
          clearable
        >
        </el-input>
      </el-col>
      <el-col
      v-else
        :class="getIsMobile ? '' : 'ml-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        style="text-align: right"
      >
        <el-input
          class="search px-0 "
          placeholder="Search"
          v-model="search_string"
          clearable
        >
        </el-input>
      </el-col>

      <el-col
        :class="getIsMobile ? '' : 'ml-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
      >
        <el-select
          v-model="selectedStatus"
          filterable
          clearable
          class="select"
          placeholder="Select Status"
        >
          <el-option
            v-for="(status, index) in statusOption"
            :key="index"
            :value="status.value"
            :label="status.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        v-if="checkPermissions('createNewDocument')"
      >
        <el-button
          plain
          :style="getIsMobile ? 'width:170px;margin-top:10px;margin-left:2px' : 'width:170px;'"
          :class="getIsMobile ? 'mr-1' : 'create-btn px-4 ml-2'"
          type="primary"
          @click="goToCreateDocument"
          >Upload Documents</el-button
        >
      </el-col>
    </el-row>
    <el-dialog
      class="DialogueStyle"
      title="Warning"
      :visible.sync="centerDialogVisible"
      width="25%"
    >
      <span v-if="this.selected_document.length == 1"
        >Are you sure you want to delete 1 selected Document?</span
      >
      <span
        v-if="
          this.selected_document.length == 0 ||
          this.selected_document.length > 1
        "
        >Are you sure you want to delete
        {{ this.selected_document.length }} selected Documents?</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="selectedDocumentDelete"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      class="DialogueStyle"
      title="Warning"
      :visible.sync="dialoguevisibility"
      width="28%"
      center
    >
      <span v-if="this.selected_document.length >= 1"
        >Only Document Owner can delete selected documents.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialoguevisibility = false">Cancel</el-button>
        <el-button type="primary" @click="dialoguevisibility = false"
          >Ok</el-button
        >
      </span>
    </el-dialog>

    <DocumentsList
      page_size="10"
      :selectedDocStatus="selectedStatus"
      :search_string="search_string"
      @deleteEvent="fetchingSelectedDelete"
    ></DocumentsList>
  </section>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import axios from "@/config/axios";

import DocumentsList from "@/components/requestDocuments/DocumentsList";
// import PermissionsHelper from "@/mixins/permissionsHelper";
export default {
  name: "requestDocuments-AllDocuments",
  props: ["pageSizes", "type", "categoryId", "groupId"],
  components: { DocumentsList },
  data() {
    return {
      document_type: '',
      is_documentDelete: false,
      dialoguevisibility: false,
      centerDialogVisible: false,
      isVisible: false,
      selected_document: [],
      loading: false,
      search_string: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      page: 1,
      configureLoading: false,
      documents: [],
      modalLoading: false,
      selectedStatus: "",
      statusOption: [
        { name: "Active", value: "ACTIVE" },
        { name: "Inactive", value: "INACTIVE" },
      ],
    };
  },
  // mixins: [PermissionsHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserType",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("documents", ["getAllCompanyUsers"]),
    getSelecetedDocStatus() {
      if (this.selecetedDocStatus == "waiting") {
        return "Need to be Sign";
      }
      return this.selecetedDocStatus;
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.type) {
      this.selectedStatus = this.$route.query.type;
    }

    var card_type = this.$route.params.card_type;
    if (card_type === '1') {
      this.document_type = "Driver's license (USA)";
    } else if (card_type === '2') {
      this.document_type = "Void Check";
    } else if (card_type === '3') {
      this.document_type = "Passports";
    } else if (card_type === '4') {
      this.document_type = "I-94";
    } else if (card_type === '5') {
      this.document_type = "Aadhar Cards";
    }
  },
  methods: {
    handleDocumentTypesClicked() {
      this.$router.push({ path: "/request-documents/select-card-type" });
    },
    setLoading() {
      bus.$emit("documentLoading", this.is_documentDelete);
    },
    goToCreateDocument() {
      this.$router.push({
        name: "upload-request-documents",
        query: { card_type: this.$route.params.card_type }
      });
    },
    fetchingSelectedDelete({ data }) {
      this.selected_document = [];
      this.selected_document = data;
    },
    checkCondition() {
      this.centerDialogVisible = true;
    },
    async selectedDocumentDelete() {
      this.centerDialogVisible = false;
      this.is_documentDelete = true;
      this.setLoading();
      this.selected_document.forEach(async (e, index) => {
        if (e) {
          let document_id = e._id;
          // await this.$store.dispatch("documents/parmanentdelete", {
          //   document_id,
          // });

          await axios.delete(`request-documents/${document_id}`);


          if (index == this.selected_document.length - 1) {
            this.$notify.success({
              title: "Success",
              message: "Documents deleted successfully",
            });
            window.location.reload();
          }
        }
      });
    },
    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.document-types {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  width: 300px;
  .document-types-left {
    width: 125px !important;
    cursor: pointer;
  }
  .document-types-right {
    color: #606266 !important;
  }
}
.all-documents-view {
  .search {
    position: relative;
    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .select {
    position: relative;
    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .title {
    letter-spacing: 0.019em;
  }
  .text-secondary-sent {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    text-align: left !important;
    color: #439ffc !important;
  }
  .text-secondary {
    font-size: 14px !important;
    font-weight: 400 !important;
    // line-height: 14px !important;
    text-align: left !important;
    color: #6c757d !important;
  }
  .doc-subs {
    font-size: 10px;
    margin-top: -13px;
    font-weight: 300;
    text-align: right;
    margin-right: 9%;
  }
  .doc-sub {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    //margin-bottom:-18px;
    text-align: left;
  }
  .doc-title {
    cursor: pointer;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 14px;
    text-align: left;

    color: #6c757d !important;
  }
  .check-font {
    color: #6c757d !important;
    font-family: "Inter", sans-serif;
  }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      background: #635e5e;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.completed::after {
      background: #08ad36;
    }
    &.draft::after {
      background: #f19203;
    }
    &.sent::after {
      background: #2f80ed;
    }
    &.active::after {
      background: #635e5e;
    }
    &.expired::after {
      background: #f03232;
    }
  }
  .custom-pagination {
    text-align: center;
    margin-top: 5px;
  }
}
</style>

<style lang="scss">
.doc-modal {
  .el-dialog__header {
    .el-dialog__title {
      text-transform: uppercase;
    }
  }
  .error {
    color: red;
  }
}
.import-dropdown-btn {
  margin-left: 10px;
  height: 40px;
  .el-button {
    height: 100%;
    font-size: 14px;
    span {
      padding: 0 10px;
    }
  }
}
.import-dropdown-btn-menu {
  .el-dropdown-menu__item {
    display: flex;
    img {
      width: 25px;
      height: 25px;
      padding: 3px;
      background: #f7f7f7;
      border-radius: 10px;
    }
    span {
      margin-top: -2px;
      margin-left: 10px;
    }
  }
}
.DialogueStyle {
  margin-top: 50px;
}
</style>
